<template>
  <v-card @click="$emit('selectItem', item)">
    
    <v-img      
      :src="item.previewUrl"
    />
    
    <v-card-title class="py-3">
      <p>
        <span v-html="item.title" style="font-weight: bold"></span><br />
        <span v-html="item.subtitle" style="white-space:normal;font-size: 0.9rem;"></span>
      </p>
    </v-card-title>
    <v-card-subtitle class="ma-0">
      <p v-html="item.category"></p>
      <p>
        <span v-html="getDatetime(item.recodingDate)"></span>
        <span v-html="' · '"></span>
        <span v-html="item.eventName"></span>
      </p>
    </v-card-subtitle>
  </v-card>
</template>

<script>
// import MediaPlayer from "./MediaPlayer";
import { dateHelper } from "_shared/helpers";
export default {
  mixins: [dateHelper],
  components: {
    // MediaPlayer,
  },
  props: {
    item: { type: Object, required: true },
  },
};
</script>

<style scoped>
p {
  margin-bottom: 0px;
  line-height: initial;
  font-size: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.v-card__subtitle p {
  font-size: 0.87rem;
}
</style>