<template>
  <v-card flat width="192" color="transparent" class="d-flex align-center justify-center">
    <div class="d-flex flex-column">
      <v-avatar size="100"  :color="$vuetify.theme.dark?'tertiary lighten-5':'tertiary'" class="mx-auto mt-3">
        <img :src="getUrl(person.imageUrl)" :alt="person.name" v-if="person.imageUrl"/>        
        <v-icon size="96" :color="$vuetify.theme.dark?'tertiary':'black'" v-if="!person.imageUrl">mdi-account</v-icon>
      </v-avatar>
      <v-card-text class="text-center">
          <p style="font-family:bold">{{ person.name }}</p>
          <p style="font-family:thin" v-html="person.description"></p>
        </v-card-text>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    person: Object,
  },  
  methods: {
    getUrl(imageUrl) {      
      return process.env.VUE_APP_ID4Server + imageUrl;
    },
  },
};
</script>

<style lang="scss" scoped>

p{
  margin-bottom: 0px;
}
</style>