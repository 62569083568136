<template>
  <div class="home div-100" ref="container">
    <v-img
      :src="require('_shared/assets/bg.jpg')"
      height="calc(100vh - 36px)"
      min-height="768"
      max-height="1080"
      style="top: -48px"
      transition="fade"
    >
      <div class="d-flex flex-column align-center div-100 justify-center mt-6">
        <h1 class="white--text event-title pb-3" v-html="$t('page.title')"></h1>




        <!-- <div
          class="
            d-flex
            flex-row
            align-space-around
            justify-space-around
            img-wrapper
            pb-14
          "
        >
          <v-img
            :src="require('_shared/assets/Logotype_weiss_MALZFABRIK.png')"
            width="145"
            class="img-logo"
            contain
          ></v-img>
          <v-img
            :src="require('_shared/assets/logo.png')"
            width="85"
            class="img-logo"
            contain
          ></v-img>
          <v-img
            :src="require('_shared/assets/Fine&Bright-weiss.png')"
            width="126"
            class="img-logo"
            contain
          ></v-img>
        </div> -->
        
        <!-- <h1
          class="white--text event-subtitle"
          v-html="$t('page.subtitle')"
        ></h1> -->


        <h1 class="white--text event-date" v-html="$t('page.date')"></h1>
        <RegisterOrLogin/>
        <!-- <h2 class="white--text event-anmeldung mt-10">{{ $t("page.registration") }}</h2> -->
      </div>
    </v-img>

    <div>

      <!-- <LandingPageDescription class="my-8" /> -->
      <!-- <EventsFrame
        id="events"
        :headline="$t('page.events.headline')"
        :EventItems="GET_EventItems"
      /> -->

  
      <EventsFrame
        id="events"
        :headline="$t('page.events.headline')"
        :EventItems="GET_EventItems"
      />

      <LandingPageDescription class="my-8" />     


      <Speakers :type="'gastgeber'" :headline="'Gastgeber'" class="my-8" />
      <Speakers :type="'speaker'" :headline="'Speaker'" class="my-8" />
      <Speakers :type="'moderator'" :headline="'Moderation'" class="my-8" />


      <AgendaTimeline :AgendaItems="GET_EventInfo.agendaItems" />

       <Locations class="my-8" />  
      <!-- <ParterFrame :headline="$t('page.partners.headline')" class="my-8" /> -->
   

 

    </div>
  </div>
</template>

<script>
import Speakers from "../components/Speakers/SpeakersFrame";
import LandingPageDescription from "../components/LandingPage/Description";
import Locations from "../components/LandingPage/Locations";
import AgendaTimeline from "@/components/Agenda/AgendaTimeline";
import RegisterOrLogin from "./components/RegisterOrLogin";
import { mapGetters } from "vuex";

export default {
  name: "Home",  
  components: {
    Speakers,
    LandingPageDescription,
    Locations,
    RegisterOrLogin,
    AgendaTimeline
  },
  computed: {
    ...mapGetters("i18n", ["GET_Current"]),
     ...mapGetters(["GET_EventInfo"]),
    ...mapGetters(["GET_EventItems"]),
  },
  async beforeCreate() {
    await this.$store.dispatch("getEventItems");
  },
};
</script>

<style lang="scss" scoped>

.event-title {
  font-family: "Bold";
  font-size: 138px;
  line-height: 1;
  padding-bottom: 48px;
}

.event-subtitle {
  font-family: "Thin";
  font-size: 24px;
}
.event-date {
  font-family: "Bold";
  font-size: 30px;
}
.event-anmeldung {
   font-family: "Thin";
  font-size: 24px;
}
.event-title,
.event-subtitle,
.event-date {
  text-align: center;
}

.img-wrapper {
  width: 500px;
}
.img-logo {
  height: 24px;
}

@import "~vuetify/src/styles/settings/_variables";
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .event-title {
    font-size: 75px;
  }
  .event-subtitle {
    font-size: 17px;
  }
  .event-date {
    font-size: 17px;
  }
  .img-wrapper {
    width: 100px;
  }
  .img-logo {
    height: 12px;
  }

  .event-anmeldung {
   font-family: "Thin";
  font-size: 18px;
}
}
</style>
