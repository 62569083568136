<template>
  <v-card flat :color="type == 'moderator' ? '' : 'transparent'">
    <v-container class="my-10">
      <v-card-title class="justify-center mb-1">
        <h1 class="component-header">{{ headline }}</h1>
      </v-card-title>
      <v-item-group>
        <v-row class="justify-center">
          <v-col v-for="person in speakers" :key="person.id" cols="auto">
            <SpeakerItem v-bind:person="person" />
          </v-col>
        </v-row>
      </v-item-group>
    </v-container>
  </v-card>
</template>

<script>
import SpeakerItem from "./SpeakerItem";
export default {
  components: {
    SpeakerItem,
  },
  props: {
    type: String,
    headline: String,
  },
  data: () => ({
    speakers: [],
  }),
  async mounted() {
    //TODO fallback all

    if (this.type === undefined || this.type == "")
      this.speakers = (await this.$axios.get(`api/person/all`)).data;
    else
      this.speakers = (
        await this.$axios.get(`api/person/all/${this.type}`)
      ).data;
  },
};
</script>

<style>
</style>