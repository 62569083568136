<template>
  <div>
    <v-data-iterator
      :items="filteredByCategory"
      :search="search"
      :hide-default-footer="window > 0"
      @current-items="current"
      :items-per-page="-1"
      :footer-props="{
        'items-per-page-options': [4, 8, 12, -1],
      }"
    >
      <template v-slot:header>
        <div>
          <v-btn
            v-if="window > 0"
            class="mr-auto align-self-center"
            icon
            color="primary"
            @click="goback"
            ><v-icon>mdi-apps</v-icon></v-btn
          >
          <v-container>
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  v-model="search"
                  clearable
                  flat
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                  label="Search"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="d-flex justify-center my-3">
                <v-chip-group column multiple v-model="tagfilter">
                  <v-chip v-for="tag in tags" :key="tag" filter color="accent">
                    {{ tag }}
                  </v-chip>
                </v-chip-group>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </template>

      <template v-slot:default="{ items }">
        <v-window v-model="window">
          <transition name="fade" mode="out-in">
            <v-window-item>
              <v-item-group>
                <v-row class="ma-3">
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    lg="3"
                    v-for="item in items"
                    :key="item.id"
                  >
                    <v-item>
                      <media-gallery-item
                        :item="item"
                        v-on:selectItem="selectItem"
                      />
                    </v-item>
                  </v-col>
                </v-row>
              </v-item-group>
            </v-window-item>
          </transition>
          <transition name="fade" mode="out-in">
            <v-window-item>
              <v-row>
                <v-col cols="12" lg="8">
                  <router-view :item="selectedItem" :key="selectedItem.id" />
                </v-col>
                <v-col cols="12" lg="4">
                  <v-sheet max-height="100vh" style="overflow-y: auto">
                    <v-item-group v-model="selectedItem">
                      <v-row no-gutters class="mt-n1 pr-1">
                        <v-col
                          class="py-1"
                          cols="auto"
                          v-for="item in currentItems"
                          :key="item.id"
                          style="width: 100%"
                        >
                          <v-item>
                            <media-gallery-list-item
                              :item="item"
                              v-on:selectItem="selectItem"
                            />
                          </v-item>
                        </v-col>
                      </v-row>
                    </v-item-group>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-window-item>
          </transition>
        </v-window>
      </template>
    </v-data-iterator>
  </div>
</template>

<script>
/*eslint-disable vue/no-unused-components*/
import MediaGalleryItem from "./MediaGalleryItem.vue";
import MediaGalleryListItem from "./MediaGalleryListItem.vue";
import MediaGalleryPlayer from "./MediaGalleryPlayer.vue";
import EventBus from "_shared/EventBus";

export default {
  components: {
    MediaGalleryItem,
    MediaGalleryListItem,
    MediaGalleryPlayer,
  },
  props: {
    mediaItems: Array,
    tags: Array,
  },
  data: () => ({
    search: "",
    tagfilter: [],
    selectedItem: {},
    window: 0,
    currentItems: [],
  }),
  methods: {
    selectItem(item) {
      this.window = 1;
      this.selectedItem = item;
      this.$router.push({ name: "Video", params: { id: item.title } });
    },
    goback() {
      EventBus.$emit("vimeo_unload");
      this.$router.replace({ name: "Mediathek" });
      this.window = 0;
    },
    current(current) {
      this.currentItems = current;
    },
  },
  created() {
    EventBus.$on("set-window", (id, title) => {
      this.window = id;
      if (id == 1) {
        console.log(title);
        this.selectedItem = this.mediaItems.find((item) => item.title == title);
      }
    });
  },
  destroyed() {
    EventBus.$off("set-window");
  },
  computed: {
    filteredByTags() {
      var filterItems = this.tagfilter.map((s) => this.tags[s]);
      if (filterItems.length > 0)
        return this.mediaItems.filter((s) =>
          filterItems.some((t) => s.tags?.includes(t))
        );
      else return this.mediaItems;
    },
    filteredByCategory() {
      var filterItems = this.tagfilter.map((s) => this.tags[s]);

      if (filterItems.length > 0)
        return this.mediaItems.filter((s) =>
          filterItems.some((t) => s.category === t)
        );
      else return this.mediaItems;
    },
  },
};
</script>

<style>
</style>