
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { storei18n } from './store'

Vue.use(VueI18n)


export const i18n = new VueI18n({
  locale: 'de-DE',
  fallbackLocale: 'de-DE',
  silentFallbackWarn: true,
  warnHtmlInMessage: false
})

const loadedLanguages = []

function setI18nLanguage(lang) {
  i18n.locale = lang
  // window.axios.defaults.headers.common['Accept-Language'] = lang
  // document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function ClearLoadedEvent() {
  loadedLanguages.forEach(lang => {
    i18n.mergeLocaleMessage(lang, { event: undefined });
  })

}
export async function LoadEvent(key) {

  if (i18n.getLocaleMessage(key).event == undefined) {
    let data = (await window.axios.get(`api/i18n/getEvent?key=${key}`)).data;
    let lang = data.lang;
    // i18n.mergeLocaleMessage(lang, { event: undefined });
    i18n.mergeLocaleMessage(lang, data.data);
    i18n.setLocaleMessage(lang, i18n.getLocaleMessage(lang));
  }

}

export async function loadLanguageAsync(lang, clientid) {

  if (loadedLanguages.includes(lang)) {
    if (i18n.locale !== lang) setI18nLanguage(lang)
    return;
  } else {
    let response = await window.axios.get(`/api/i18n/get?clientid=${clientid}&code=${lang}`)

    if (response.data.data != null) {
      let msgs = response.data.data;
      ///Set Fallback lang or switch to Fallback
      // console.log(lang +':'+ response.data.lang)
      loadedLanguages.push(lang);
      i18n.setLocaleMessage(lang, msgs);
      setI18nLanguage(lang);
    }
  }
}


const plugin = {
  async install(Vue, store) {
    if (!store.hasModule('i18n')) {
      store.registerModule('i18n', storei18n);

      await store.dispatch('i18n/getLanguages');
    }
  }
}

export default plugin;

