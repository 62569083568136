<template>
  <v-card    
    outlined
    @click="$emit('selectItem', item)"
    class="d-flex flex-row div-100"
  >
  <v-sheet class="d-flex" :width="$vuetify.breakpoint.smAndDown ? 118 : 178" color="appshell">
    <img
      class="my-auto"
      :width="$vuetify.breakpoint.smAndDown ? 118 : 178"
      :src="item.previewUrl"
    />
  </v-sheet>

    <div
      class="div-100 d-flex flex-column py-1"
      style="min-width: 172px; width: 100%"
    >
      <v-card-title class="py-0 mb-1">
        <p>
          <span v-html="item.title" style="font-weight: bold"></span><br />
          <span v-html="item.subtitle" style="white-space:normal;"></span>
        </p>
      </v-card-title>
      <v-card-subtitle class="ma-0 py-0 mt-auto"> 
        <p>
        <span v-html="getDatetime(item.recodingDate)"></span>
        <span v-html="' · '"></span>
        <span v-html="item.eventName"></span>
      </p>
      </v-card-subtitle>
    </div>
  </v-card>
</template>

<script>
// import MediaPlayer from "./MediaPlayer";
import { dateHelper } from "_shared/helpers";
export default {
  mixins: [dateHelper],
  components: {
    // MediaPlayer,
  },
  props: {
    item: { type: Object, required: true },
  },
};
</script>

<style scoped>
p {
  margin-bottom: 0px;
  line-height: 1.1rem;
  font-size: 0.82rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>