<template>
  <v-card flat>
    <media-player :mediaItem="item" :hasControls="true" />
    <v-card-title>
      <p>
        <span v-html="item.title" style="font-weight: bold"></span><br />
        <span v-html="item.subtitle"></span>
      </p>
    </v-card-title>
    <v-card-subtitle class="ma-0">
      <p v-html="item.category"></p>
      <p>
        <span v-html="getDatetime(item.recodingDate)"></span>
        <span v-html="' · '"></span>
        <span v-html="item.eventName"></span>
      </p>
    </v-card-subtitle>
    <v-divider v-if="item.description"/>
    <v-card-text>
      <p v-html="item.description"></p>
    </v-card-text>
  </v-card>
</template>

<script>
import MediaPlayer from "./MediaPlayer";
import { dateHelper } from "_shared/helpers";
export default {
  mixins: [dateHelper],
  components: {
    MediaPlayer,
  },
  props: {
    item: { type: Object, required: true },    
  },
  computed:
  {
    mediaItem()
    {
      return this.item;
    }
  }  ,
  watch:{
    item:{
      handler(val)
      {
        console.log(val)
      }
    }
  }
};
</script>

<style scoped>
p {
  margin-bottom: 0px;
  line-height: initial;
  /* font-size: 1rem; */
}

</style>