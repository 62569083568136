<template>
  <v-container fluid class="div-10">
    <div class="div-100">
      <v-row no-gutters>
        <v-col cols="12" lg="9" ref="stream">
          <v-tabs
            color="white"
            background-color="transparent"
            active-class="activeTab"
          >
            <!-- <v-tab link :to="{ name: 'Stream' }" replace>Stream</v-tab> -->
            <!-- <v-tab link :to="{ name: 'Conference', params:{roomid:0}}" replace>Conference 1</v-tab>
            <v-tab link :to="{ name: 'Conference',  params:{roomid:1}}" replace>Conference 2</v-tab> -->
          </v-tabs>

          <v-responsive :aspect-ratio="16 / 9">
            <router-view></router-view>
          </v-responsive>

          <v-col cols="12" class="py-7">
            <v-card-title class="justify-center">
              <h2 v-html="$t('page.title')"></h2>
            </v-card-title>
          </v-col>

          <v-col v-if="!$vuetify.breakpoint.mobile">
            <Info />
          </v-col>
        </v-col>

        <v-col
          cols="12"
          lg="3"
          :class="$vuetify.breakpoint.mobile == true ? 'pt-2 px-0' : 'pl-2'"
        >
        <!-- height="calc(100vh - 48px - 36px - 48px - 12px)" -->
          <v-sheet            
            height="calc(100% - 48px)"
            v-if="getTabs.length > 1"
          >          
            <v-tabs
              id="tabs-wrapper"
              color="primary"
              background-color="transparent"
              grow
              active-class="activeTab"
            >
              <v-tab v-for="tab in getTabs" :key="tab.id" :href="tab.href">
                {{ tab.name }}
              </v-tab>

              <v-tab-item v-for="tab in getTabs" :key="tab.id" :id="tab.id">
                <component v-bind:is="tab.typ" v-bind:roomName="tab.roomName" />
              </v-tab-item>
            </v-tabs>
          </v-sheet>

          <component v-else v-bind:is="getTabs[0].typ" />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
/*eslint-disable vue/no-unused-components*/
// import QnAView from "_shared/QnA/QnAView";
// import ChatView from "_shared/ChatRoom/ChatRoomView";
import VimeoFrame from "@/components/IFrameServices/VimeoFrame";
import SlidoFrame from "@/components/IFrameServices/SlidoFrame";
import Info from "../components/Info";

import { mapGetters } from "vuex";

export default {
  components: {
    SlidoFrame,
    VimeoFrame,
    Info,

  },
  created() {
    this.$router.replace({
      name: "Stream",
    });
  },
  computed: {
    getTabs() {
      if (!this.$vuetify.breakpoint.mobile)
        return this.tabs.filter((s) => s.id !== "info");
      else return this.tabs;
    },
    ...mapGetters("notification", ["roomState"]),
  },
  watch: {
    roomState: {
      handler(value) {
        if (value != "live") {
          // this.$router.push({
          //   name: "Event",
          //   params: { eventId: this.$store.state.event.event_id },
          // });
          this.$router.push({
            name: "WaitingRoom",
            params: { eventId: this.$store.state.event.event_id },
          });
        }
      },
    },
  },

  data: () => ({
    tabs: [
      { href: "#info", name: "Info", id: "info", typ: "Info" },
      { href: "#slido", name: "Slido", id: "slido", typ: "SlidoFrame" },
      // { href: "#qna", name: "QnA", id: "qna", typ: "QnAView", roomName:'Global' },
      // {
      //   href: "#chat",
      //   name: "Chat",
      //   id: "chat",
      //   typ: "ChatView",
      //   roomName: "Global",
      // },
      // {
      //   href: "#chat2",
      //   name: "Chat2",
      //   id: "chat2",
      //   typ: "ChatView",
      //   roomName: "Intern",
      // },
    ],
  }),
};
</script>

<style lang="scss">
.activeTab {
  font-family: Bold;
  font-weight: 100;
}

#tabs-wrapper {
  height: 100% !important;
}
#tabs-wrapper,
.v-window,
.v-window-item {
  height: 100%;
}

// .v-slide-group__prev, .v-slide-group__next
// {
//   display: none;
// }
</style>