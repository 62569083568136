<template>
  <div class="pb-16 mb-16" v-if="GET_EventInfo != undefined">
    <v-parallax
      style="top: -48px"
      :src="getUrl(GET_EventInfo.eventMetaInfo.imageUrl)"
      height="310"
    ></v-parallax>
    <v-container>
      <v-card flat elevation="1">
        <v-card-actions>
          <v-btn icon color="primary" :to="{ name: 'Home', hash: '#events' }"
            ><v-icon>mdi-arrow-left</v-icon></v-btn
          >
        </v-card-actions>

        <v-card-title>
          <p v-html="$t('event.title')"></p>
          <!-- <p v-html="GET_EventInfo.eventMetaInfo.title"></p> -->
        </v-card-title>
        <v-card-subtitle v-html="$t('event.date')"> </v-card-subtitle>
        <v-card-text>
          <p v-html="$t('event.description.text')"></p>
          <div v-if="$te('event.description.subjects.headline')">
            <p
              v-html="$t('event.description.subjects.headline')"
              v-if="$te('event.description.subjects.headline')"
            />

            <ul
              v-for="(item, i) in $t('event.description.subjects.items')"
              :key="i"
            >
              <li v-html="item"></li>
            </ul>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-btn
            :disabled="!GET_EventInfo.active"
            color="primary"
            append
            :to="{
              name: 'WaitingRoom',
              params: { eventid: this.$store.state.event_id },
            }"
            >Enter</v-btn
          >
        </v-card-actions>
      </v-card>

      <AgendaTimeline :AgendaItems="GET_EventInfo.agendaItems" />
 
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AgendaTimeline from "@/components/Agenda/AgendaTimeline";
// import ParterFrame from "../../components/Partners/PartnerFrame";
import { urlHelper } from "_shared/helpers";
import { LoadEvent } from "_shared/i18n";

export default {
  name: "Event",
  components: {
    AgendaTimeline,
    // ParterFrame
  },
  mixins: [urlHelper],
  computed: {
    ...mapGetters(["GET_EventInfo"]),
  },
  async beforeCreate() {
    await this.$store.dispatch("getEventInfo");
    await LoadEvent(this.$store.getters["i18n/GET_Current"]);  
  },
};
</script>

<style>
</style>