<template>
  <v-responsive :aspect-ratio="16 / 9">
    <div
      class="div-100"
      ref="vimeoplayer"
      :class="hasControls == false ? 'disabled' : ''"
    ></div>
  </v-responsive>
</template>

<script>
import Player from "@vimeo/player";
import EventBus from "_shared/EventBus";

export default {
  props: {
    mediaItem: { videoUrl: "about:blank" },
    hasControls: Boolean,
  },
  data: () => ({
    player: undefined,
  }),
  mounted() {
    var options = {
      url: this.mediaItem.videoUrl,
      responsive: true,
      controls: this.hasControls,
    };
    console.log(this.mediaItem.videoUrl);
    this.player = new Player(this.$refs.vimeoplayer, options);
    this.player.on("loaded", () => {
      if (this.hasControls) this.player.play();
    });
  },
  created() {
    EventBus.$on("vimeo_unload", () => {
      this.unload();
    });
  },
  destroyed() {
    EventBus.$off("vimeo_unload");
  },
  methods: {
    unload() {
      this.player.unload();
    },
  },
  watch: {
    mediaItem: {
      handler(val) {        
        this.$nextTick(() => {
          this.player.loadVideo(val.videoUrl);
          this.player.play();
        });
      },
    },
  },
};
</script>

<style>
.disabled {
  pointer-events: none;
}
</style>