import { ClearLoadedEvent } from '_shared/i18n'

export const storeEvent = {
  //  namespaced: false,
  state: {
    event_id: '',
    eventItems: [],
    eventInfo: undefined
  },
  getters: {
    EventId(state) {
      // state.event_id = window.sessionStorage.getItem('eventId');
      return state.event_id
    },
    GET_SelectedEvent(state)
    {
      return state.eventItems.find(s=>s.id==state.event_id)
    },
    GET_EventItems(state)
    {
      return state.eventItems
    },
    GET_EventInfo(state)
    {
      return state.eventInfo
    }
  },
  mutations: {
    SET_EventId(state, payload) {

      if (payload === null) {
        window.sessionStorage.removeItem('eventId', state.event_id)
        // ClearLoadedLanguage();
      }
      else
        window.sessionStorage.setItem('eventId', payload);

      if (payload != state.event_id) {
        // ClearLoadedLanguage();
        state.eventInfo = undefined;
        ClearLoadedEvent();
      }

      state.event_id = payload;
    },
    SET_EventItem(state, payload) {
      state.eventInfo = payload;
    },
    SET_EventItems(state, payload) {
      state.eventItems = payload;
    },   
  },
  actions: {
    async setEventState({commit, state },payload){
      console.log(payload)      
      let data = (await window.axios.post(
        `api/trigger/SetEventState?eventid=${payload.id}&active=${payload.active}&trigger=${payload.triggerEnabled}&roomState=${payload.roomState}`
      )).data;

      var idx = state.eventItems.indexOf(
        state.eventItems.find((s) => s.id == data.eventItem.id)
      );      
      state.eventItems.splice(idx, 1, data.eventItem);
      commit('SET_EventItem',data.eventItem)
    },
    async getEventInfo({ commit, state },reload) {
      console.log("load info")
      let eventInfo;
      if (state.eventInfo == undefined || reload) {
        try {
          eventInfo = (await window.axios.get(`api/event/eventinfo?eventid=${state.event_id}`)).data;         
          commit('SET_EventItem', eventInfo)
        } catch (e) {
          console.log(e);
        }
        return eventInfo;
      } else return state.eventInfo;
    },
    async getEventItems({dispatch, commit, state },reload=false) {
      let eventItems;
      if (state.eventItems.length == 0 || reload) {
        try {
          eventItems = (await window.axios.get(`api/event/AllEvents?active=${true}&all=${true}`)).data;
          commit('SET_EventItems', eventItems)
          if (eventItems.length == 1) {
            commit('SET_EventId', eventItems[0].id);
            await dispatch('getEventInfo')
          }
        } catch (e) {
          console.log(e);
        }
        return eventItems;
      } else return state.eventItems
    }
  },
}

export default {}