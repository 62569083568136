import { loadLanguageAsync } from '.'

export const storei18n = {
    namespaced: true,
    state: {
        current: undefined,
        languages: []
    },
    getters: {
        GET_Current(state) {
            if (state.current === undefined) {
                if (window.localStorage.getItem('locale') == undefined) {
                    window.localStorage.setItem('locale', window.navigator.language);
                    state.current = window.localStorage.getItem('locale');
                }
                else
                    state.current = window.localStorage.getItem('locale')
                return state.current
            } else {
                return state.current
            }
        },
        GET_Languages(state) {
            return state.languages;
        }
    },
    mutations: {
        SET_Language(state, payload) {
            window.localStorage.setItem('locale', payload)
            window.axios.defaults.headers.common['Accept-Language'] = payload
            document.querySelector('html').setAttribute('lang', payload)
            state.current = payload;
        },
        SET_Languages(state, payload) {
            state.languages = payload
        }
    },
    actions: {
        async setLanguage({ commit }, payload) {
            await loadLanguageAsync(payload.lang, payload.clientid)
            commit('SET_Language', payload.lang);
        },
        async getLanguages({ commit, state }) {
            if (state.languages.length == 0) {
                let langs = (await window.axios.get('api/i18n/all')).data;
                return commit('SET_Languages', langs);
            } else return state.languages;
        }
    }
}