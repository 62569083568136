import { mgr } from ".";
import { router } from '.'
import { store } from '.'

export const storeAccount = {
    namespaced: true,
    state: {
        isLogIn: false,
        user: null,
        errorMsg: ''
    },
    mutations: {
        SET_User(state, payload) {
            state.user = payload;
            if (payload != null)
                state.isLogIn = true;
            else
                state.isLogIn = false;
        },
        SET_ErrorMsg(state, payload) {
            state.errorMsg = payload;
        }
    },
    actions: {
        async getUser(context) {
            let user = null;
            try {
                user = await mgr.signinSilent()
                context.commit('SET_User', user)
                return user;
            } catch (e) {
                console.log(e.message)
                if (e.message == 'Unauthorized') {
                    store.commit('account/SET_ErrorMsg', 'Unauthorized')
                    router.push({ name: 'Error' }).catch(() => { });
                } else
                    if (e.message == 'login_required') {
                        //router.push({ name: 'Error' }).catch(() => { });
                        window.account.login();
                    }

            }
            return user
        },
    },
    getters:
    {
        getEventIds(state) {
            return state.user?.profile.eventid
        },
        getRoles(state) {
            return state.user?.profile.role;
        },
        isLogIn(state) {
            return state.isLogIn
        },
        user(state) {
            return state.user;
        },
        username(state) {
            const profile = state.user?.profile;
            if (profile != null) {
                if (profile.given_name != null && profile.family_name != null)
                    return `${profile.given_name} ${profile.family_name}`;
                else
                    return profile.name;
            } else
                return undefined;

        }
    },
}

export default {}