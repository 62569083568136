<template>
  <v-card
    flat
	rounded="0"
    color="transparent"
    width="100%"
    :height="$vuetify.breakpoint.smAndDown ? '80vh' : '100%'"
  >
    <iframe
      :src="link"
      frameborder="0"
      allow="autoplay *; fullscreen *; microphone *; camera *"
      allowfullscreen
      style="width: 100%; height: 100%"
      class="d-flex"
    ></iframe>
  </v-card>
</template>

<script>
export default {
  props: {
    RoomId: { required: true },
  },
  data: () => ({
    link: "about:blank",
  }),
  watch: {
    RoomId: {
      async handler() {
        await this.createRoomUrl();
      },
    },
  },
  async mounted() {
    await this.createRoomUrl();
  },
  methods: {
    async createRoomUrl() {
      this.link =(await this.$axios.get(`/api/meeting/join?roomId=${this.RoomId}`)).data;
    },
  },
};
</script>

<style>
</style>