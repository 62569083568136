<template>


  <v-card color="transparent" outlined>
      <v-card-title :class="center ? 'justify-center' : 'justify-center'">
      <h2>Locations</h2>
    
    </v-card-title>
     <v-card-text
      class="d-flex"
      :class="center ? 'justify-center' : 'justify-center'"
    >
  
    <!-- class="d-sm-flex align-content-start" -->
    <v-container >
      <v-row>
        <v-col>
          <v-card-text  class="pa-0 white--text" style="font-size: 14px" :class="center ? 'text-center' : 'text-center'">
            <p> Hannover <br> Adam-Stegerwald-Straße 9-15 <br> 30851 Langenhagen</p>
          </v-card-text>
        </v-col>

        <v-col>
          <v-card-text  class="pa-0 white--text" style="font-size: 14px" :class="center ? 'text-center' : 'text-center'">
            <p> Stuttgart <br> Raiffeisenstraße 39 <br> 70794 Filderstadt</p>
          </v-card-text>
        </v-col>
      </v-row>
    </v-container>
     </v-card-text>
 </v-card>

</template>

<script>
export default {
  props: {
    center: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.hashtag {
  word-break: break-word;
  font-size: 64px;
}



@import "~vuetify/src/styles/settings/_variables";
@media #{map-get($display-breakpoints, 'xs-only')} {
.hashtag {  
  font-size: 26px;
}
}

</style>