import Vue from 'vue'
import EventBus from '_shared/EventBus'

const plugin = {
    install(vue, store) {

        const signalR = require('@microsoft/signalr')

        const connection = new signalR.HubConnectionBuilder()
            .withUrl(process.env.VUE_APP_ID4Server + "/hubs/qnahub", { accessTokenFactory: () => store.getters['account/user'].access_token })
            .configureLogging(signalR.LogLevel.Warning)
            .withAutomaticReconnect()
            .build();


        connection.on('receive_JoinQnaRoom', async (qnaroom) => {
            store.commit(`qna-${qnaroom.eventId}-${qnaroom.title}/SET_QnARoom`, qnaroom)
            await store.dispatch(`qna-${qnaroom.eventId}-${qnaroom.title}/getQuestions`, store.state.client_id == "operator.client" ? false : true);
        });

        connection.on('update_Question', (questionInfo) => {
            if (store.hasModule(`qna-${questionInfo.eventId}-${questionInfo.qnaRoom}`)) {
                store.commit(`qna-${questionInfo.eventId}-${questionInfo.qnaRoom}/UPDATE_Questions`, questionInfo);
            }
        })

        connection.on('clear', (qnaRoom) => {
            if (store.hasModule(`qna-${qnaRoom.eventId}-${qnaRoom.title}`)) {
                store.unregisterModule(`qna-${qnaRoom.eventId}-${qnaRoom.title}`)
                EventBus.$emit('qnaroom_init');
            }
        })


        const hubFunctions = {
            start: async () => {
                try {
                    if (connection.state == signalR.HubConnectionState.Disconnected) {
                        await connection.start();
                        console.log("Q&A Hub Start");
                    }
                } catch (err) {
                    console.log(err);
                    setTimeout(hubFunctions.start, 5000);
                }
            },
            stop: async () => {
                await connection.stop();
                console.log("QnA Hub Stop")
            },
            askQuestion: (question, roomName, eventid) => {
                connection.invoke('AskQuestion', question, store.getters[`qna-${eventid}-${roomName}/GET_QnARoom`].id, eventid)
            },
            JoinQnARoom: (roomName, eventid) => {
                connection.invoke('JoinQnARoom', roomName, store.state.account.user.profile.sub, eventid)
            },
            updateQuestion: async (item) => {
                await connection.invoke('updateQuestion', item.eventId, item.qnaRoom, item.question)
            },
            likeQuestion: async (item) => {
                await connection.invoke('likeQuestion',
                    item.question.id,
                    item.question.votedUser.find((s) => s == store.state.account.user.profile.sub) ? false : true,
                    item.eventId)
            }
        };


        Vue.qnahub = hubFunctions
        Vue.prototype.$qnahub = hubFunctions
        window.qnahub = hubFunctions;

        // Vue.prototype.$qnahub.connection = connection;        
    }
}

export default plugin;
