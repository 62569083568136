<template>
  <v-app v-if="$te('page')">
    <v-app-bar :color="this.$router.currentRoute.matched.some(({ name }) => this.$store.state.coloredHeader.includes(name))?'appshell': 'appshell'" app dense flat hide-on-scroll>
     <div class="d-flex align-center justify-center div-100">
      <router-link class="d-flex align-center" to="/">
      
      <div class="d-flex align-center">
        <v-img
          class="mx-auto pt-1"
          contain
          :src="require('_shared/assets/logo.png')"
          transition="fade-transition"
          :width="$vuetify.breakpoint.xsOnly?76:92"
        />

         <v-img
          class="shrink ml-16"
          contain
          :src="require('_shared/assets/Holtmann_Logo_weiß.png')"
          transition="fade-transition"
           :width="$vuetify.breakpoint.xsOnly?122:180"
        />
      </div>
      
      </router-link>
      </div>

      <div />
      <v-spacer></v-spacer>
      
      <ToolbarUser />
      <!-- <ToolbarLanguage
          v-if="GET_Languages.length > 1"  
      /> -->
      
    </v-app-bar>

     <!-- <v-img
      :src="require('_shared/assets/bg.jpg')"
      height="calc(100vh - 36px)"
      min-height="768"
      max-height="1080"
      style="top: -48px; position: absolute"
      transition="fade"
      
    /> -->

    <v-main>     
      <router-view />
       <ConsentView />
    </v-main>

    <v-footer color="appshell" app fixed>
      <FooterView :light="true" :useCredits="false" />
    </v-footer>
  </v-app>
</template>

<script>

import ToolbarUser from "_shared/account/components/ToolbarUser";
import FooterView from "_shared/Footer/FooterView.vue";
// import ToolbarLanguage from "_shared/i18n/components/ToolbarLanguage";
import ConsentView from "_shared/components/ConsentView";
import { mapGetters } from 'vuex';

export default {
  name: "App",
  components: {
    ToolbarUser,
    FooterView,    

    ConsentView,    
  },
  computed:{
    ...mapGetters('i18n',['GET_Languages'])
  }
};
</script>

<style lang="scss">
.component-header {  
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 3rem;
  text-align: left;
  // color:var(--v-primary-base);
}
.description{
  font-size: 18px;
}
</style>
