<template>
  <div class="d-flex" :class="useColumn ? 'flex-column' : 'flex-row ml-6'">
    <!-- <a
      class="pr-2"
      target="_blank"
      :href="contact.startsWith('/') ? undefined : contact"
      @click="setType('Contact', contact)"
      :class="
        this.$parent.light || $vuetify.theme.dark === true
          ? 'txt-light'
          : 'txt-dark'
      "
    >
      {{ $t("footer.contact") }}
    </a> -->

    <a
      class="pr-2"
      target="_blank"
      :href="dataprivacy.startsWith('/') ? undefined : dataprivacy"
      :class="
        this.$parent.light || $vuetify.theme.dark === true
          ? 'txt-light'
          : 'txt-dark'
      "
      @click="setType('Dataprivacy', dataprivacy)"
    >
      {{ $t("footer.dataprivacy") }}
    </a>
    <a
      class="pr-2"
      target="_blank"
      :href="imprint.startsWith('/') ? undefined : imprint"
      :class="
        this.$parent.light || $vuetify.theme.dark === true
          ? 'txt-light'
          : 'txt-dark'
      "
      @click="setType('Imprint', imprint)"
    >
      {{ $t("footer.imprint") }}
    </a>

    <v-dialog v-model="dialog" scrollable min-width="50vw" max-width="768">
      <component v-bind:is="this.type" />
    </v-dialog>
  </div>
</template>

<script>
import EventBus from "_shared/EventBus";
import Imprint from "../dialogs/Imprint";
import Dataprivacy from "../dialogs/Dataprivacy";
import Contact from "../dialogs/Contact";

import { mapGetters } from "vuex";

export default {
  components: {
    Imprint,
    Dataprivacy,
    Contact,
  },
  props: {
    useColumn: Boolean,
  },
  data: () => ({    
    dialog: false,
    type: "",
  }),
  computed: {
    ...mapGetters(['contact','imprint','dataprivacy'])
  },
  async created() {
    await this.$store.dispatch('getConfig');

    EventBus.$on("showDialog", (payload) => {      
      this.dialog = payload.show;
      this.type = payload.type;
    });
  },
  onBeforeDestroy() {
    EventBus.$off("showDialog");
  },
  methods: {
    setType(type, link) {
      if (link.startsWith("/")) {
        this.dialog = !this.dialog;
        this.type = type;
      }
    },
  },
};
</script>

<style>
</style>