<template>

  <!-- <v-card flat color="transparent" > -->
    <div class="d-flex flex-row flex-md-column align-content-center">
      <!-- <v-card-title>
        <h1
          class="component-header"
          v-html="
            $t('home.description.headline') != 'home.description.headline'
              ? $t('home.description.headline')
              : ''
          "
        ></h1>
      </v-card-title>
      <v-card-text :class="center ? 'text-center' : ''">
        <p class="mb-0 description" v-html="$t('home.description.text')"></p>
      </v-card-text>

  <v-card flat color="transparent" >
    <v-container class="d-sm-flex align-content-start">
      <v-card-title>
        <h1
          class="component-header"
          v-html="
            $t('home.description.headline') != 'home.description.headline'
              ? $t('home.description.headline')
              : ''
          "
        ></h1>
      </v-card-title>
      <v-card-text :class="center ? 'text-center' : ''">
        <p class="mb-0 description" v-html="$t('home.description.text')"></p>
      </v-card-text>
    </v-container>
  </v-card> -->

  <v-card color="transparent" outlined>
    <!-- class="d-sm-flex align-content-start" -->
    <v-container >
      <v-row>
        <v-col cols="12" md="auto"  class="d-flex align-start align-md-center">
          <v-card-title class="pa-0">
            <h1
              class="component-header hashtag"
              v-html="
                $t('home.description.headline') != 'home.description.headline'
                  ? $t('home.description.headline')
                  : ''
              "
            ></h1>
          </v-card-title>
        </v-col>

        <v-col>
          <v-card-text  class="pa-0" :class="center ? 'text-center' : ''">
            <p class="mb-0 description" v-html="$t('home.description.text')"></p>
          </v-card-text>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
    </div>
  <!-- </v-card> -->
</template>

<script>
export default {
  props: {
    center: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.hashtag {
  word-break: break-word;
  font-size: 64px;
}



@import "~vuetify/src/styles/settings/_variables";
@media #{map-get($display-breakpoints, 'xs-only')} {
.hashtag {  
  font-size: 26px;
}
}

</style>