/*eslint-disable no-unused-vars */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import { loadLanguageAsync } from '_shared/i18n'

import { routesAccount } from '_shared/account/routes'
import { account } from '_shared/account'

import store from '../store'
import EventBus from "_shared/EventBus";

import EventView from '../views/pages/EventView'
import WaitingRoomView from '../views/pages/WaitingRoomPopup'
import LiveContainerView from '../views/pages/LiveContainerView'

import VimeoFrame from '../components/IFrameServices/VimeoFrame'
import MeetingFrame from '../components/IFrameServices/MeetingFrame'

import MediaLibraryView from '../components/MediaLibrary/MediaLibraryView'
import MediaGalleryPlayer from '../components/MediaLibrary/components/MediaGalleryPlayer'
import { i18n } from '_shared/i18n'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requireAuth: false },
    beforeEnter: (to, from, next) => {
      store.commit("SET_EventId", null);
      store.commit("notification/SET_EventStateItem", null);
      next()
    },
  },

  {
    path: '/:eventId',
    component: { render(c) { return c('router-view') } },
    meta: { requireAuth: false },
    children: [
      {
        path: '',
        name: 'Event',
        redirect:'waitingroom',
        component: EventView,
        meta: { requireAuth: false }
      },
      {
        path: 'waitingroom',
        name: 'WaitingRoom',
        component: WaitingRoomView,
        meta: { requireAuth: true },
        beforeEnter: (to, from, next) => {
          if (store.getters['notification/roomState'] === 'live' || account.methods.hasRole('user.test'))
            router.replace({ name: 'Live', params: { eventId: to.params.eventId } });
          else
            next();
        }
      },
      {
        path: 'live',
        name: 'Live',
        component: LiveContainerView,
        meta: { requireAuth: true },
        beforeEnter: (to, from, next) => {
          if (store.getters['notification/roomState'] === 'live' || account.methods.hasRole('user.test'))
            next();
          else
            router.replace({ name: 'WaitingRoom', params: { eventId: to.params.eventId } })
        },
        children: [
          {
            path: 'stream',
            name: 'Stream',
            component: VimeoFrame,
            meta: { requireAuth: true },
          },
          // {
          //   path: 'conference/:roomid',
          //   name: 'Conference',
          //   component: MeetingFrame,
          //   meta: { requireAuth: true },
          //   props: route => ({ RoomId: route.params.roomid })
          // },
        ]
      }
    ]
  },
  ...routesAccount,

  {
    ///Default routes
    path: '*',
    meta: { requireAuth: false },
    beforeEnter: () => {
      router.push('/')
    }
  },
]


import goTo from 'vuetify/es5/services/goto'

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0

    if (to.matched.some(({ name }) => name == 'Live' || name == 'Mediathek')) {
      return { x: 0, y: 0 }
    } else
      if (to.hash) {
        scrollTo = to.hash
      } else if (savedPosition) {
        scrollTo = savedPosition.y
      }

    return goTo(scrollTo)
  },
})


router.afterEach(async () => {
  await loadLanguageAsync(store.getters['i18n/GET_Current'], store.state.client_id)
})

router.beforeEach(async (to, from, next) => {

  if (from === VueRouter.START_LOCATION && !(to.path == "/account/silentrenew" || to.path =="/account/callback")) {          
    await window.account.silentSignIn();

    if (to.params.eventId != undefined) {
      let evt = await GetEvent(to, from, next, true)
      if (evt == false)
        return;
    }
  }

  if (to.matched.some(record => record.meta.requireAuth)) {
    if (to.params.eventId != undefined) {
      let evt = await GetEvent(to, from, next, true)
      if (evt == false)
        return;
    }

    if (store.getters['account/user'] != null) {
      await Vue.StartAllHubs();
      next();
    } else {
      let user = await window.account.silentSignIn();//store.dispatch('account/getUser');
      if (user == null) {
        next(false);
        window.account.login();
      }
      else {
        next();
      }
    }
  } else {
    if (to.params.eventId != undefined) {
      let evt = await GetEvent(to, from, next, true)
      if (evt == false)
        return;
    }
    next();
  }

})


async function GetEvent(to, from, next, isPublic) {
  let res = await window.axios.get(`api/event/ExistEvent?eventid=${to.params.eventId}&isActive=${true}`);
  if (res.data.exist == true && (account.methods.hasEvent(res.data.eventId) || isPublic)) {
    if (res.data.active == false)
      router.replace('/');
    else {
      if (store.state.notification.EventStateItem == null) {
        store.commit('SET_EventId', res.data.eventId);
        store.commit('notification/SET_EventStateItem', (await window.axios.get(`api/event/GetEventState?eventid=${res.data.eventId}`)).data);
      }
    }
  }
  else {
    console.log("Event not allowed")
    next(router.push('/'));
    return false;
  }
}

export default router
