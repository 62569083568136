<template>
  <v-card flat color="transparent" class="div-100" min-height="500">
    <InfoDescription class="mb-5"/>   
    <AgendaTimeline :AgendaItems="EventItem.agendaItems"/> 
  </v-card>
</template>

<script>
import InfoDescription from './InfoDescription'
import AgendaTimeline from "@/components/Agenda/AgendaTimeline";

export default {
  components: {
    InfoDescription,   
    AgendaTimeline 
  },
  data: () => ({
    EventItem: {eventMetaInfo:{}},
  }),
  async created() {
    this.EventItem = await this.$store.dispatch("getEventInfo");    
  },
};
</script>

<style>
</style>