<template>
  <v-card flat color="transparent" class="noColor">
    <v-card-title :class="center ? 'justify-center' : 'justify-start'">
      <h2 class="component-header" v-html="$t('home.description.headline')"></h2>
    </v-card-title>
    <v-card-subtitle>
      <!-- <h2 class="component-header" v-html="$t('home.description')"></h2> -->
    </v-card-subtitle>
    <v-card-text :class="center ? 'text-center' : ''">
      <p class="description white--text" v-html="$t('home.description.text')"></p>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  props: {
    center: Boolean,
  },  
};
</script>

<style>
/* .description{
  font-size: 30px;
} */
</style>