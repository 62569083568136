<template>
 <v-img
      :src="require('_shared/assets/bg.jpg')"
      height="calc(100vh - 36px)"
      min-height="768"
      max-height="1080"
      style="top: -48px"
    >

  <v-container
    class="div-100 d-flex flex-column align-center justify-center text-center"
  >

  
    <v-card flat color="transparent">

      <v-card-subtitle class="white--text">
        <h1 class="event-waitingroom text-center">{{ $t("waitingroom.title") }}</h1>        
      </v-card-subtitle>
      <v-card-text class="d-flex flex-row text-center white--text">
        <h2
          class="event-waitingroom-subtitle text-center"
          v-html="$t('waitingroom.btn_test')"
        ></h2>

    
      </v-card-text>      
       <v-btn color="black" class="mt-8 mx-2" fab large @click="dialog = !dialog">
        <img
          height="48"
          :src="require('_shared/assets/play.svg')"
        />
      </v-btn>

    </v-card>

    <v-dialog v-model="dialog" min-width="50vw" max-width="768">
      <v-card class="d-flex flex-column" color="tertiary">
        <v-responsive :aspect-ratio="16 / 9">
          <iframe
            ref="vimeoplayer"
            :src="link"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
            style="width: 100%; height: 100%"
            class="d-flex"
          ></iframe>
        </v-responsive>
        <!-- <v-card-text class="py-3">
          <p>{{ $t("waitingroom.popup.text") }}</p>
        </v-card-text> -->
      </v-card>
    </v-dialog>
  </v-container>
 </v-img>
</template>


<script>
import Player from "@vimeo/player";

export default {
  data: () => ({
    dialog: false,
    link: String,
    player: undefined,
  }),
  computed: {
    isActive() {
      return this.$store.getters["notification/isActive"];
    },
  },
  async beforeCreate() {
    this.link = (
      await this.$axios.get(`/api/webapp/config?clientid=${this.$store.state.client_id}&key=link.vimeo.Preview`)
      
    ).data.value;console.log("LINK ENTERED: " + this.link);
  },
  watch: {
    dialog: {
      handler(val) {
        if (!val) this.player.pause();
        else {
          this.$nextTick(() => {
            if (this.player == undefined)
              this.player = new Player(this.$refs.vimeoplayer);
            else this.player.play();
          });
        }
      },
    },
    isActive: {
      handler(value) {
        if (value) {
          this.$router.push({ name: "Live" });
        }
      },
    },
  },
};
</script>