<template>
  <v-container fluid>
    <div class="div-100">
      <MediaGallery :mediaItems="mediaItems" :tags="tags" />    
    </div>
  </v-container>
</template>

<script>
import MediaGallery from "./components/MediaGallery";
import EventBus from '_shared/EventBus'

export default {
  components: {
    MediaGallery,    
  },  
  async beforeCreate() {
    let data = (await this.$axios.get("api/medialibrary/all")).data;
    this.mediaItems = data.mediaItems;
    this.tags = data.tags;
  },
  data: () => ({   
    mediaItems: [],
    tags: [],    
  }),   
  beforeRouteUpdate(to, from, next)
  {
    console.log(to)
    if(to.name!="Video")
    EventBus.$emit("set-window",0,to.params.id)
    else
    EventBus.$emit("set-window",1, to.params.id)

    next()
  },
};
</script>

<style>
</style>